export default [
    {
        title: 'menu.monte.monte',
        icon: ['fal', 'venus-mars'],
        accessRights: 'SAILL',
        children: [
            // {
            //     title: 'menu.monte.tableau_bord',
            //     icon: ['fal', 'tachometer-slow'],
            //     to: { name: '' }
            // },
            // {
            //     title: 'menu.monte.depistages_vaccins',
            //     icon: ['fal', 'syringe'],
            //     to: { name: '' }
            // },
            {
                title: 'menu.monte.contracts',
                icon: ['fal', 'file-contract'],
                to: { name: 'ContractListv2' },
                href: '#/monte/contrats'
            },
            {
                title: 'menu.monte.planning_monte',
                icon: ['fal', 'alarm-clock'],
                to: { name: 'PlanningMonteBeta' },
                href: '#/planning_monte'
            },
			{
                title: 'menu.monte.gestion_expedition',
                icon: ['fad', 'truck-loading'],
                to: { name: 'monteReservation' },
                href: '#/monte/expeditions'
            },
            {
                title: 'menu.monte.suivi_dps',
                icon: ['fal', 'passport'],
                to: { name: 'carnet_saillie' },
                accessRights: 'DPS',
                href: '#/carnet_saillie'
            },
            {
                title: 'menu.monte.suivi_sanitaire',
                icon: ['fal', 'file-medical'],
                to: { name: 'sanitaire' },
                accessRights: 'SANI',
                href: '#/sanitaire'
			},
            {
                title: 'menu.monte.import_dn_as',
                icon: ['fal', 'download'],
                to: { name: 'import_dn_as' },
                accessRights: 'DPS',
                href: '#/import_dn_as'
            },
			{
				title: 'menu.monte.bilan',
				icon: ['fal', 'table'],
				to: { name: 'saisonMonte' },
                href: '#/bilans/monte'
			},
            {
                title: 'menu.monte.stock_semence_distant',
                icon: ['fal', 'crutch'],
                to: { name: 'stockDistant' },
                href: '#/monte/stock_distant',
                accessRights: 'SEMENCE'
            },
            {
                title: 'menu.monte.add_contract',
                icon: ['fal', 'crutch'],
                to: { name: 'CreateContract' }
            },
        ]
    },
    {

        title: 'menu.congelation.congelation',
        icon: ['fal', 'vials'],
        accessRights: 'CONGEL',
        children: [
            {
                title: 'menu.congelation.not_inventoried',
                icon: ['fal', 'tasks'],
                to: { name: 'CongelationNotInventoried' },
                href: '#/congelation/not_inventoried'
            },
            {
                title: 'menu.congelation.prestockage',
                icon: ['fal', 'database'],
                to: { name: 'CongelationPrestockage' },
                href: '#/congelation/prestockage'
            },
            {
                title: 'monte.congelation.doses',
                icon: ['fas', 'shipping-fast'],
                to: { name: 'CongelationDoses'},
                href: '#/congelation/doses'
            },
            {
                title: 'monte.congelation.commandes',
                icon: ['fad', 'truck-loading'],
                to: { name: 'CongelationCommande' },
                href: '#/congelation/commandes'
            },
            {
                title: 'monte.congelation.expeditions',
                icon: ['fad', 'truck-loading'],
                to: { name: 'CongelationExpedition' },
                href: '#/congelation/expeditions'
            },
            {
                title: 'monte.congelation.statistiques',
                icon: ['fal', 'chart-pie-alt'],
                to: { name: 'CongelationStatistiques'},
                href: '#/congelation/statistiques'
            },
            {
                title: 'monte.congelation.statistiques_lot',
                icon: ['fal', 'chart-pie-alt'],
                to: { name: 'CongelationStatistiquesLot'},
                href: '#/congelation/statistiques_lot'
            },
            {
                title: 'monte.congelation.cuve_shipped',
                icon: ['fas', 'shipping-fast'],
                to: { name: 'CongelationCuveShipped'},
                href: '#/congelation/cuve_shipped'
            }
        ]
    }
]